<template>
  <v-dialog v-model="visible" width="500">
    <v-card>
      <v-card-title v-if="user && user.emailVerified" class="text-h5 primary">
        <p style="color: #ffffff">Generating Report</p>
      </v-card-title>

      <v-card-title v-else class="text-h5 primary">
        <p style="color: #ffffff">Failed Generating Report</p>
      </v-card-title>

      <v-card-text v-if="user && user.emailVerified">
        <p>
          You will receive an email with a copy of your report as soon as it is
          available.
        </p>
        <p>
          Note - Please check your spam folder for your report. To avoid your
          report ending up in your spam folder please add
          <b><em>reports@propcheck.com.au</em></b> to your contact list.
        </p>
        <p>
          Note - Properties with more than one street frontage (i.e. corner
          blocks) may be recognised as having more than one street address.
        </p>
        <p>
          Your report will also be accessible through your
          <router-link :to="{ name: 'Dashboard' }">Dashboard</router-link>
          page.🙂
        </p>
      </v-card-text>

      <v-card-text
        v-else-if="
          user != undefined &&
          user.emailVerified != undefined &&
          !user.emailVerified
        "
      >
        <p>
          You cannot generate a report until you have verified your email
          address.
        </p>
        <p>
          Note - If you haven't received a verification email then you can
          request a new one in
          <router-link :to="{ name: 'My Account' }">My Account</router-link>
          page. Please be sure to check your spam folder also.
        </p>
      </v-card-text>

      <v-card-text v-else>
        Please <router-link :to="{ name: 'Login' }">login</router-link> or
        <router-link :to="{ name: 'Sign Up' }">sign up</router-link> to generate
        a report. 🙂
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const EVENT_NAME = "close-report-generated-dialog";

export default {
  props: {
    value: Boolean,
    user: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit(EVENT_NAME);
      },
    },
  },
  methods: {
    closeDialog: function () {
      this.$emit(EVENT_NAME);
    },
  },
};
</script>
